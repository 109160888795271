import { mapActions, mapGetters } from 'vuex';
import TelemetryService from '../services/telemetry';

export default {
  computed: {
    ...mapGetters([
      'taskDetails',
      'taskProcesses',
      'indexToStepsMapping',
      'negativeSteps',
      'optionalSteps',
      'selectedVirtualTask',
      'virtualTaskNegativeSteps',
      'virtualTaskOptionalSteps',
      'virtualTaskIndexToStepMap',
    ]),
  },
  watch: {
    taskDetails(value) {
      !!value && this.setTaskNameAndProcess(value);
    },

    // taskProcesses(_) {
    //   this.setIndexToStepsMapping();
    // },
    indexToStepsMapping(_) {
      if (this.videoList[0] && !this.videoList[0]['missedStepsName'])
        this.videoList = this.videoList.map(this.appendMissedSteps);
    },
  },
  methods: {
    ...mapActions([
      'setTaskNameAndProcess',
      'setIndexToStepsMapping',
      'setIsFetchingCycles',
      'setIsFetchingTrace',
    ]),

    getVideoList(filters) {
      return new Promise(async (resolve, _) => {
        // const queryParams = this.getQueryParams(filters);
        this.setIsFetchingTrace(true);
        const [error, data] = await TelemetryService.getTraceVideosV2(
          false,
          filters
        );
        this.setIsFetchingTrace(false);
        if (error) {
          this.toast.error('Error Occurred while fetching cycles!');
          console.log({ error });
          return;
        }
        this.videoCount = data.count;
        this.videoList = data.results.map((el) => ({
          ...el,
          inference_time: el.inference_time.startsWith('-1')
            ? el.cycle_time
            : el.inference_time,
        }));
        this.videoList = this.videoList.map(this.appendMissedSteps);
        this.showRedirectButton = true;
        resolve();
      });
    },

    getQueryParams(filters) {
      const queryParams = {
        org_id: this.organization,
        device_ids: filters.device_ids,
        task_id: filters.task_id,
        datetime_start: filters.datetime_start,
        datetime_end: filters.datetime_end,
        cycle_type: filters.cycleType,
      };
      if (filters.minCycleTime)
        queryParams['min_cycle_time_in_bin'] = filters.minCycleTime;
      if (filters.maxCycleTime)
        queryParams['max_cycle_time_in_bin'] = filters.maxCycleTime;
      return queryParams;
    },

    appendMissedSteps(video) {
      if (!video) return;
      var temp =
        video.missed_steps
          ?.split(', ')
          .filter(
            (e, i, a) =>
              a.indexOf(e) === i && !!e && this.indexToStepsMapping[e]
          ) || [];

      const NegativeSteps = Object.values(this.negativeSteps).map((x) =>
        x.toString()
      );

      const optionalSteps = Object.values(this.optionalSteps).map((x) =>
        x.toString()
      );

      temp = temp.filter(
        (item) => !NegativeSteps.includes(item) && !optionalSteps.includes(item)
      );

      return {
        ...video,
        missedStepsName: temp.map((step) => this.indexToStepsMapping[step]),
      };
    },

    getTaskDetails() {
      if (this.selectedVirtualTask) {
        return {
          indexToStepsMapping: this.virtualTaskIndexToStepMap,
          negativeSteps: this.virtualTaskNegativeSteps,
          optionalSteps: this.virtualTaskOptionalSteps,
        };
      }
      return {
        indexToStepsMapping: this.indexToStepsMapping,
        negativeSteps: this.negativeSteps,
        optionalSteps: this.optionalSteps,
      };
    },

    filterNegStepsFromMissedSteps(missed_steps) {
      const {
        indexToStepsMapping,
        negativeSteps,
        optionalSteps,
      } = this.getTaskDetails();

      let all_steps = {};
      let filteredSteps = {};
      for (let step in missed_steps) {
        all_steps[step.trim()] = missed_steps[step];
      }

      Object.keys(indexToStepsMapping).forEach((step) => {
        if (
          !negativeSteps.includes(Number(step)) &&
          !optionalSteps.includes(Number(step))
        ) {
          const stepName = indexToStepsMapping[step]?.toString()?.trim();
          filteredSteps[stepName] = all_steps[stepName] || 0;
        }
      });
      return filteredSteps;
    },
  },
};
