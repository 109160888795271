<template>
  <a-row :gutter="[8, 8]" class="h-100">
    <a-col span="10">
      <a-card
        size="small"
        hoverable
        class="h-100 d-flex flex-column"
        :body-style="{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '1px',
          flex: '1 1 auto'
        }"
      >
        <a-spin v-if="isFetchingVideoUrl" size="large" class="m-auto" />

        <video
          v-else-if="video?.isVideoExists"
          id="trace-page-single-video-play"
          ref="video"
          controls
          class="w-100"
          height="450"
          :src="video?.fileURL"
          @play="onPlay"
          @seeking="handleSeeking"
          @ended="onEnd"
          @loadeddata="onLoad"
        />

        <div v-else class="w-100 d-flex" style="height: 40vh !important">
          <a-result title="Untraced Cycle!" class="m-auto">
            <template #icon>
              <exclamation-circle-outlined class="text-danger" />
            </template>
          </a-result>
        </div>

        <div
          v-if="negativeStepsList.length > 0"
          class="py-1 w-100 mt-1 h-100 d-flex flex-column"
        >
          <h6>Negative step(s) Performed</h6>
          <div
            id="inference-md-neg-steps"
            style="height: 1px; overflow-y: auto; flex: 1 1 auto;"
          >
            <a-tag
              v-for="(negativeStep, index) in negativeStepsList"
              :id="'inference-neg-step' + index"
              :key="index"
              class="my-1"
              color="error"
            >
              {{ stepsTranslationMapping[negativeStep.name] }}
            </a-tag>
          </div>
        </div>
      </a-card>
    </a-col>

    <a-col span="8">
      <steps-list
        :steps-list="stepsList"
        :negative-steps-list="negativeSteps"
        :optional-steps-list="optionalSteps"
        :missed-steps-list="missedSteps"
        :background-time="backgroundTimeNew"
        :current-step-list="currentStepList"
        :cycle="currentCycle"
        :detected-steps="detectedSteps"
        :steps-time="stepsTime"
        :is-task-details-loading="isFetchingTaskDetails"
        :is-prediction-file-not-exist="isPredictionFileNotExist"
        :steps-translation-mapping="stepsTranslationMapping"
        :process-details="processDetails"
        @handleClickSubStep="handleClickSubStep"
      />
    </a-col>

    <a-col span="6" class="d-flex flex-column">
      <missed-steps
        :missed-steps="missedSteps"
        :title="'Missed Steps'"
        :steps-translation-mapping="stepsTranslationMapping"
        :is-task-details-loading="isFetchingTaskDetails"
      />
      <a-card
        v-if="video?.isVideoExists"
        title="Comments"
        size="small"
        hoverable
        class="h-50 d-flex flex-column mt-2"
        style="flex:60%"
        :body-style="{ height: '1px', flex: '1 1 auto', overflowY: 'auto' }"
      >
        <comments
          :comments="comments"
          :selected-video="video"
          :is-minimized="true"
          :is-fetching-video-url="isFetchingVideoUrl"
          @updateVideo="args => $emit('updateVideo', args)"
        />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex';
import MissedSteps from 'src/components/user-panel/pages/DeployModel/components/MissedStep';
import StepsList from 'src/components/user-panel/pages/DeployModel/components/StepsList';
import { StatisticalLogger } from 'src/service/telemetry/core/StatisticalLogger';
import Comments from 'src/components/user-panel/pages/trace/Comments.vue';
import axios from 'axios';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export default {
  name: 'RecordedInference',
  components: {
    MissedSteps,
    StepsList,
    Comments,
    ExclamationCircleOutlined
  },
  props: {
    isFetchingVideoUrl: { type: Boolean, default: false },
    video: { type: Object, default: () => ({}) },
    stepsList: { type: Array, default: () => [] },
    comments: { type: Array, default: () => [] }
  },
  emits: ['loading', 'updateVideo'],
  data() {
    return {
      frame: 0,
      interval: null,
      currentStepList: [],
      currentCycle: 1,
      filePreds: null,
      predictions: {},
      stepStartTime: {},
      stepsTime: {},
      framesToShowMissedStepsOnList: [],
      substepList: [],
      missedStepsList: [],
      backgroundTime: 0,
      missedSteps: [],
      negativeStepsList: [],
      detectedSteps: {},
      isPredictionFileNotExist: false,

      statisticalLogger: null,
      processDetails: {},
      backgroundTimeNew: 0
    };
  },

  computed: {
    ...mapGetters([
      'negativeSteps',
      'optionalSteps',
      'taskStepsCount',
      'taskJson',
      'isFetchingTaskDetails',
      'stepsTranslationMapping'
    ]),
    taskProcesses() {
      return this.$store.getters.taskProcesses;
    }
  },

  watch: {
    frame(currentFrame) {
      this.currentStepList = this.predictions[currentFrame] || [];
      // this.updateCycle();
      this.updateStepsTimeDict();
      this.testing();
    },

    video(value) {
      if (value) {
        this.updatePredictions();
        this.updateMissedSteps();
      } else {
        this.resetState();
      }
    },

    stepsList(list) {
      this.substepList = list.filter(el => el.type === 'sub-step');
    },

    currentStepList(list) {
      list.forEach(this.addStepInDetectedStepsDict);
      this.updateNegativeStepsList();
    },

    currentCycle() {
      this.detectedSteps = {};
      this.stepsTime = {};
    }
  },

  mounted() {
    this.substepList = this.stepsList.filter(el => el.type === 'sub-step');
    this.updatePredictions();
    this.updateMissedSteps();
  },

  beforeUnmount() {
    this.resetState();
    clearInterval(this.interval);
  },

  methods: {
    testing() {
      // console.log('Frame', this.frame);
      const stepsObj = this?.filePreds[this.frame];
      if (stepsObj) {
        const kwargs = {
          pred: stepsObj.pred,
          time: this.currentDate,
          fps: this?.video?.fps || 30
        };
        this.statisticalLogger.sendPredToProcess(kwargs);

        const processes = this.statisticalLogger.taskInfo.processes;
        this.makeProcessDetails(processes);
        const resultant = processes.reduce((res, p) => {
          const { subStepsTimes } = p;
          const { [this.taskStepsCount]: background, ...rest } = subStepsTimes;
          // this.backgroundTime += background;
          this.backgroundTime = background;
          return { ...res, ...rest };
        }, {});
        this.stepsTime = resultant;
      }
    },

    makeProcessDetails(processes) {
      this.processDetails['noOfProcesses'] = processes.length;
      for (let i = 0; i < processes.length; i++) {
        this.processDetails[i] = [
          processes[i].requiredStepsForStart[0],
          processes[i].requiredStepsForEnd.slice(-1)[0]
        ];
      }
    },

    async onLoad() {
      this.statisticalLogger = new StatisticalLogger(
        this.onCycleEnd,
        this.onCycleStart,
        null,
        null,
        null,
        this.setCycleTime,
        this.setBackgroundTime
      );
      const kwargs = {
        platform: 'cloud',
        bucket: `${this.organization}-training`,
        allTasks: this
      };
      await this.statisticalLogger.setupVars(kwargs);
      this.currentDate = new Date();
    },

    setCycleTime(input) {
      this.cycleTime = { ...this.cycleTime, ...input };
    },

    setBackgroundTime(input) {
      this.backgroundTimeNew = input;
    },

    onCycleStart(currentProcess, cycleCount) {
      this.currentCycle = cycleCount;
    },

    onCycleEnd(currentProcess, processes) {
      if (
        currentProcess
          .getMissedSteps()
          .map(value => this.substepList[value]?.name).length > 0
      ) {
        let temp = currentProcess
          .getMissedSteps()
          .map(value => this.substepList[value]?.name);
        this.missedStepsBuffer = [...this.missedStepsBuffer, ...temp];
      }
      let processNumber = currentProcess.id;
      this.cycleTime = { ...this.cycleTime, [processNumber]: '0.00' };
      if (currentProcess.id == processes[processes.length - 1].id) {
        this.missedSteps = [];
        this.missedSteps.push(...this.missedStepsBuffer);
        this.missedStepsBuffer = [];
        this.backgroundTimeNew = 0;
      }
    },

    onPlay() {
      const self = this;
      if (!self.isPredictionFileNotExist)
        this.interval = setInterval(self.updateFrames, 1000 / 30 / 2);
      // this.stepsTime = {};
    },

    onEnd() {
      this.detectedSteps = {};
      this.currentCycle = 1;
      clearInterval(this.interval);
    },

    addStepInDetectedStepsDict(step) {
      const temp = { ...this.detectedSteps };
      const tempCycle = { ...temp[this.currentCycle] };
      tempCycle[step] = true;
      temp[this.currentCycle] = tempCycle;
      this.detectedSteps = temp;
    },

    updateNegativeStepsList() {
      const negativeStepIndexes = this.currentStepList?.filter(value =>
        this.negativeSteps?.includes(value)
      );
      this.negativeStepsList = negativeStepIndexes.map(
        index => this.substepList[index]
      );
    },

    updateCycle() {
      const cyclesInterval = this.framesToShowMissedStepsOnList;
      for (let [index, value] of cyclesInterval.entries()) {
        if (value + 30 >= this.frame) {
          this.currentCycle = index + 1;
          break;
        }
      }
    },

    updateFrames() {
      try {
        const num =
          this.$refs?.video?.currentTime.toFixed(5) * (this.video?.fps || 30);
        this.frame = Math.floor(num);
        // this.updateMissedSteps();
      } catch (error) {
        console.log('update frame error', error);
        this.resetState();
      }
    },

    updateMissedSteps() {
      if (!this.video?.missed_steps) return;
      this.missedSteps = this.video.missedStepsName;
    },

    async updatePredictions() {
      if (!this.video) return;
      const { per_frame_prediction_file_url } = this.video;
      try {
        const { data } = await axios.get(per_frame_prediction_file_url);

        const {
          preds,
          frames_to_show_missed_steps_on,
          lists_of_misssed_steps
        } = data;
        this.setPredictions(preds);
        this.setStepStartTime(preds);
        this.filePreds = preds;
        this.framesToShowMissedStepsOnList = frames_to_show_missed_steps_on;
        this.missedStepsList = lists_of_misssed_steps;
      } catch (error) {
        this.isPredictionFileNotExist = true;
        this.filePreds = null;
        this.predictions = {};
        this.detectedSteps = {};
      }
    },

    setStepStartTime(preds) {
      var tempstepStartTime = {};
      Object.entries(preds).forEach(([frame, value]) => {
        value.pred.forEach(key => {
          if (!(key in tempstepStartTime)) tempstepStartTime[key] = frame;
        });
      });
      this.stepStartTime = tempstepStartTime;
    },

    setPredictions(preds) {
      if (Array.isArray(Object.values(preds)[0])) {
        this.predictions = preds;
        return;
      }
      const tempPreds = {};
      Object.entries(preds).forEach(([key, value]) => {
        tempPreds[key] = value.pred;
      });

      this.predictions = tempPreds;
    },

    updateStepsTimeDict() {
      if (!this.filePreds) return;
      if (Array.isArray(Object.values(this.filePreds)[0])) return;
      this.addTimeToStepsTimeDict(this.filePreds[this.frame]);
    },

    addTimeToStepsTimeDict(stepObj) {
      if (!stepObj) return;
      const stepIndex = stepObj.pred[0];

      if (stepIndex === this.taskStepsCount) {
        this.backgroundTime = stepObj.times;
      }

      this.stepsTime = {
        ...this.stepsTime,
        [stepIndex]: stepObj.times
      };
    },

    handleSeeking(e) {
      if (this.isPredictionFileNotExist) return;
      const currentFrame = Math.floor(e.target.currentTime * this.video.fps);
      for (let i = 0; i <= currentFrame; i++) {
        const { pred } = this.filePreds[i];
        const [step] = pred;
        this.addStepInDetectedStepsDict(step);

        this.addTimeToStepsTimeDict(this.filePreds[i]);
      }
    },

    handleClickSubStep(stepIndex) {
      if (!(stepIndex in this.stepStartTime)) return;
      const frame = this.stepStartTime[stepIndex];
      this.$refs.video.currentTime = frame / this.video.fps;
    },

    resetState() {
      this.frame = 0;
      this.currentStepList = [];
      this.predictions = {};
      this.currentCycle = 1;
      this.detectedSteps = {};
      this.isPredictionFileNotExist = false;
      this.stepsTime = {};
      clearInterval(this.interval);
    }
  }
};
</script>

<style scoped></style>
