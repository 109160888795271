<template>
  <a-card hoverable class="w-100" :loading="isLoading">
    <template #title>
      <a-typography-title :level="5" class="card-title">
        {{ card.label }}
      </a-typography-title>
    </template>

    <template #extra>
      <a-tooltip :title="card.description">
        <QuestionOutlined class="info-icon" />
      </a-tooltip>
    </template>

    <a-row>
      <a-col :span="16">
        <a-typography-title :level="3" class="card-value">
          {{ card.value }}
        </a-typography-title>
      </a-col>

      <a-col
        v-if="Object.hasOwn(card, 'goodCycles')"
        :span="4"
        class="d-flex flex-column "
      >
        <a-typography-text class="text-secondary">
          Good Cycles
        </a-typography-text>
        <a-typography-text strong>
          {{ card.goodCycles }}
        </a-typography-text>
      </a-col>

      <a-col
        v-if="Object.hasOwn(card, 'badCycles')"
        :span="4"
        class="d-flex flex-column"
      >
        <a-typography-text class="text-secondary">
          Bad Cycles
        </a-typography-text>
        <a-typography-text strong>
          {{ card.badCycles }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { QuestionOutlined } from '@ant-design/icons-vue';
import {
  cardIndicesForFirstDateRangeWithComparison,
  cardIndicesForSecondDateRange,
} from 'src/config/charts-config.js';

export default {
  components: {
    QuestionOutlined,
  },
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    cardIndex: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'isFetchingCycles',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison',
    ]),
    isLoading() {
      const {
        showCompareAnalytics,
        isFetchingCycles,
        cardIndex,
        isFetchingAnalyticsForComparison,
      } = this;
      if (showCompareAnalytics) {
        if (cardIndicesForFirstDateRangeWithComparison.includes(cardIndex))
          return isFetchingCycles;
        else if (cardIndicesForSecondDateRange.includes(cardIndex))
          return isFetchingAnalyticsForComparison;
      } else {
        return isFetchingCycles;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.card-title {
  color: #213547;
}
.card-value {
  letter-spacing: 1px;
  line-height: 1.2;
}

.info-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8em !important;
}
</style>
