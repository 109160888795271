import apiHelper from './index';

const getVideoMetaData = async (payload, spinner = true) => {
  return await apiHelper.post(
    `organization/get_video_metadata`,
    payload,
    spinner
  );
};

const fetchTaskRecords = async (taskId, spinner = true, queryParams = {}) => {
  return await apiHelper.get(
    `organization/task/upload/${taskId}/`,
    spinner,
    queryParams
  );
};

export default {
  getVideoMetaData,
  fetchTaskRecords,
};
